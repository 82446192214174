import React from 'react'

import Allstate from '../../../images/allstate.png'
import Progressive from '../../../images/progressive.png'
import Liberty from '../../../images/liberty-mutual.png'
// import Geico from '../../../images/geico.png'
import Esurance from '../../../images/esurance.png'
import Farmers from '../../../images/farmers.png'

const Partners = () => {
  return (
    <div className="partners-section bg-orange-light alt">
      <div className="container">
        <ul className="partners-list">
          <li>
            <img src={Allstate} alt="Allstate" />
          </li>
          <li>
            <img src={Liberty} alt="Liberty Mutual" />
          </li>
          <li>
            <img src={Progressive} alt="Progressive" />
          </li>
          {/* <li>
            <img src={Geico} alt="Geico" />
          </li> */}
          <li>
            <img src={Esurance} alt="esurance" />
          </li>
          <li>
            <img src={Farmers} alt="Farmers Insurance" />
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Partners
